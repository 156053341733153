import React from "react";
import { Link } from "gatsby";
import Layout from "../../components/layout";
import { Container, Row, Col } from "react-bootstrap";
// Images
import { StaticImage } from "gatsby-plugin-image";
import Seo from "../../components/seo";

const BikeStands = () => {
    return (
        <Layout>
                  <Seo
        title="BIKE STANDS - BIKESAFE"
        description="We want to make the UK the No.1 cycle destination once again. Every bike stand that we stock is tailored to your individual needs and offers you some of the most competitive prices on the market."
      />
            <Container>
                <Row>
                    <Col sm="12">
                        <h1 className="mt-5 mb-5 text-center uppercase">
                            Bike Stands
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                        <p className="flow-text">
                        We want to make the UK the No.1 cycle destination once again. Every bike stand that we stock is tailored to your individual needs and offers you some of the most competitive prices on the market.
                        </p>
                        <p className="flow-text">
                         View our range of bike stands below:</p>
                    </Col>
                </Row>
            </Container>
            <Container>
            <Row>
                <hr/>
          <Col sm="12" lg="6" className="p-2">
            <Link to="/product/99">
              <StaticImage placeholder="blurred" className="fluid" src="./product/99/homepage-99.jpg"></StaticImage>
              <h3 className="uppercase mt-3">Sheffield Bike Stand</h3>
              <p>The classic and popular design is perfect for those seeking great value, low maintenance and minimalist bike parking.</p>
              <p className="shop-now">Get a Quote</p>
            </Link>
          </Col>
          <Col sm="12" lg="6" className="p-2">
            <Link to="/product/100">
              <StaticImage placeholder="blurred" className="fluid" src="./product/100/homepage-100.jpg"></StaticImage>
              <h3 className="uppercase mt-3">Black Sheffield Bike Stand</h3>
              <p>The shape allows the bike frame & wheel to be securely locked on to the stand. The classic and popular design is perfect for those seeking great value.</p>
              <p className="shop-now">Get a Quote</p>
            </Link>
          </Col>
        </Row>
            </Container>
        </Layout>
    )
}

export default BikeStands;